exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontaktformular-js": () => import("./../../../src/pages/kontaktformular.js" /* webpackChunkName: "component---src-pages-kontaktformular-js" */),
  "component---src-pages-kurse-js": () => import("./../../../src/pages/kurse.js" /* webpackChunkName: "component---src-pages-kurse-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-anmeldung-js": () => import("./../../../src/templates/anmeldung.js" /* webpackChunkName: "component---src-templates-anmeldung-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-kurs-js": () => import("./../../../src/templates/kurs.js" /* webpackChunkName: "component---src-templates-kurs-js" */),
  "component---src-templates-post-gallery-js": () => import("./../../../src/templates/postGallery.js" /* webpackChunkName: "component---src-templates-post-gallery-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

